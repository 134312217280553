<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name">
          修改昵称
        </span>
      </h>
      <div class="form">
        <div class="input-wrap">
          <cube-input v-model="name" placeholder="请输入昵称" />
        </div>
        <div class="btn-wrap login">
          <cube-button @click="resetName">保存</cube-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { mapState, mapActions, mapMutations } from "vuex";
import { update } from "@/api/user";
export default {
  components: {
    h
  },
  data() {
    return {
      showFlag: false,
      name: ""
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  created() {
    this.name = this.user.nickName ? this.user.nickName : "";
  },
  methods: {
    ...mapMutations({
      showToast: "global/SET_TOAST"
    }),
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    resetName() {
      const value = this.name;
      const chinese = /[\u4e00-\u9fa5]/gm;
      const chineseLen = value.match(chinese) ? value.match(chinese).length : 0;
      const allLen =
        value.length === chineseLen
          ? chineseLen * 2
          : value.length - chineseLen + chineseLen * 2;
      const minLen = 4;
      const maxLen = 20;
      if (allLen < minLen || allLen > maxLen) {
        this.showToast("昵称长度应为4-20位字符(汉字占两位字符)");
        return;
      }
      const params = {
        nickName: this.name
      };
      update(params).then(res => {
        if (!res) {
          return;
        }
        this.getUserInfo();
        this.hide();
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #f0f0f0
  z-index 11
  .form
    padding 30px 0 0
    margin 0 15px
    .input-wrap
      .cube-input
        background #fff
    .login
      margin 25px 15px 0
</style>
