<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name">
          个性签名
        </span>
      </h>
      <div class="form">
        <div class="input-wrap">
          <cube-textarea v-model="signature" placeholder="请输入个性签名" />
        </div>
        <div class="btn-wrap login">
          <cube-button @click="resetSignature">保存</cube-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { mapState, mapActions, mapMutations } from "vuex";
import { update } from "@/api/user";
export default {
  components: {
    h
  },
  data() {
    return {
      showFlag: false,
      signature: ""
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  created() {
    this.signature = this.user.signature ? this.user.signature : "";
  },
  methods: {
    ...mapMutations({
      showToast: "global/SET_TOAST"
    }),
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    resetSignature() {
      if (!this.signature) {
        this.showToast("请输入个性签名");
        return;
      }
      const params = {
        signature: this.signature
      };
      update(params).then(res => {
        if (!res) {
          return;
        }
        this.getUserInfo();
        this.hide();
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #f0f0f0
  z-index 11
  .form
    padding 30px 0 0
    margin 0 15px
    .input-wrap
      .cube-input
        background #fff
    .login
      margin 25px 15px 0
</style>
