<template>
  <transition name="slide">
    <div class="setting">
      <h :isback="true">
        <span class="name">个人资料</span>
      </h>
      <ul class="list">
        <li class="border-bottom-1px">
          <div class="l">
            <span class="text">账号</span>
          </div>
          <div class="r">
            <span class="text" v-text="editPhone(user.phone)" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="avatarEditShow">
          <div class="l">
            <span class="text">头像</span>
          </div>
          <div class="r">
            <img :src="resetUrl(user.avatar)" class="avatar" />
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="showResetName">
          <div class="l">
            <span>昵称</span>
          </div>
          <div class="r">
            <span class="text" v-text="user.nickName" />
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="showSexActive">
          <div class="l">
            <span>性别</span>
          </div>
          <div class="r">
            <span class="text" v-text="sex[user.sex]" />
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="showDatePicker">
          <div class="l">
            <span>生日</span>
          </div>
          <div class="r">
            <span class="text" v-text="user.birthday" />
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="showAddressPicker">
          <div class="l">
            <span>所在地</span>
          </div>
          <div class="r">
            <span class="text" v-text="user.area" />
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="showResetSignature">
          <div class="l">
            <span>个性签名</span>
          </div>
          <div class="r ellips">
            <span class="text" v-text="user.signature" />
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
        <li class="border-bottom-1px" @click="showResetpassword">
          <div class="l">
            <span>修改密码</span>
          </div>
          <div class="r">
            <i class="cubeic-arrow arrow" />
          </div>
        </li>
      </ul>
      <div class="out">
        <cube-button @click="relogin">退出登录</cube-button>
      </div>
      <my-upload
        v-model="avatarEditShowFlag"
        field="img"
        :width="150"
        :height="150"
        :url="imgUpUrl"
        :noSquare="true"
        :noCircle="true"
        :value="false"
        img-format="png"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
      />
      <reset-name ref="name" />
      <reset-signature ref="signature" />
      <reset-password ref="password" name="重置密码" @hide="relogin" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import resetName from "@/components/user/reset-name";
import resetPassword from "@/components/login/reset-password";
import { mapState, mapActions } from "vuex";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import { update } from "@/api/user";
import cityList from "@/assets/js/city";
import ResetSignature from "@/components/user/reset-signature.vue";
export default {
  components: {
    h,
    resetName,
    resetPassword,
    myUpload,
    ResetSignature
  },
  data() {
    return {
      sex: ["保密", "女", "男", "保密"],
      avatarEditShowFlag: false,
      imgUpUrl: `${process.env.VUE_APP_API}:8088/admin/api/common/upload.json`
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/_getUserInfo",
      clear: "user/clear"
    }),
    avatarEditShow() {
      this.avatarEditShowFlag = true;
    },
    avatarEditHide() {
      this.avatarEditShowFlag = false;
    },
    editPhone(str) {
      return str ? `${str.substr(0, 3)}****${str.substr(7, 4)}` : "";
    },
    cropSuccess(imgDataUrl) {
      this.avatar = imgDataUrl;
    },
    cropUploadSuccess(jsonData) {
      this.avatar = jsonData.object;
      this.avatarChange();
    },
    avatarChange() {
      const params = {
        avatar: this.avatar
      };
      update(params).then(res => {
        if (!res) {
          return;
        }
        this.getUserInfo();
      });
    },
    showResetName() {
      this.$refs.name.show();
    },
    showResetpassword() {
      this.$refs.password.show();
    },
    showSexActive() {
      this.$createActionSheet({
        title: "",
        active: this.user.sex - 1,
        data: [
          {
            content: "女"
          },
          {
            content: "男"
          },
          {
            content: "保密"
          }
        ],
        onSelect: (item, inx) => {
          const params = {
            sex: inx + 1
          };
          update(params).then(res => {
            if (!res) {
              return;
            }
            this.getUserInfo();
          });
        }
      }).show();
    },
    showDatePicker() {
      if (!this.datePicker) {
        this.datePicker = this.$createDatePicker({
          title: "",
          min: new Date(1960, 1, 1),
          max: new Date(),
          value: new Date(),
          format: {
            year: "YYYY",
            month: "MM",
            date: "DD"
          },
          onSelect: this.dateHandle
        });
      }
      this.datePicker.show();
    },
    dateHandle(date, selectedVal, selectedText) {
      const params = {
        birthday: `${selectedText[0]}-${selectedText[1]}-${selectedText[2]}`
      };
      update(params).then(res => {
        if (!res) {
          return;
        }
        this.getUserInfo();
      });
    },
    showAddressPicker() {
      const addressData = cityList;
      addressData.forEach(province => {
        province.children = province.sub;
        province.children.forEach(city => {
          city.children = city.sub;
        });
      });
      this.addressPicker = this.$createCascadePicker({
        title: "",
        data: addressData,
        alias: {
          value: "code",
          text: "name"
        },
        onSelect: this.addressHandle
      });
      this.addressPicker.show();
    },
    addressHandle(selectedVal, selectedIndex, selectedText) {
      const params = {
        areaId: selectedVal[selectedVal.length - 1],
        area: `${selectedText[0]} ${selectedText[1]} ${selectedText[2]}`
      };
      update(params).then(res => {
        if (!res) {
          return;
        }
        this.getUserInfo();
      });
    },
    showResetSignature() {
      this.$refs.signature.show();
    },
    relogin() {
      this.clear();
      this.$router.push("/login");
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.setting
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .list
    margin 10px 14px
    li
      display flex
      padding 11px
      font-size 14px
      color #383A40
      overflow hidden
      .l
        display flex
        align-items center
      .r
        flex 1
        text-align right
        .text,.avatar,.arrow
          display inline-block
          vertical-align middle
        .avatar
          width 38px
          height 38px
          border-radius 50%
        .arrow
          margin-left 5px
          color #C6C6C6
  .out
    margin 30px 49px 0
    .cube-btn
      border-radius 25px
      background #F0F0F0
      font-size 17px
      color #1E88E4
</style>
