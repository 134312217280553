<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name" v-text="name" />
      </h>
      <div class="form">
        <div class="input-wrap country-code border-bottom-1px">
          <div class="label">国家与地区</div>
          <div class="select" @click="showAliasPicker">
            <span
              v-text="
                `
                ${defaultCountry.chineseShortName}(${defaultCountry.countryCode})
                `
              "
            />
            <i class="cubeic-arrow" />
          </div>
        </div>
        <div class="input-wrap phone-wrap border-bottom-1px">
          <div class="input">
            <cube-input
              v-model="loginParams.phone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="btn">
            <cube-button
              :disabled="pwdCodeTime > 0"
              @click="getPwdCode"
              v-text="pwdCodeTime <= 0 ? '获取验证码' : pwdCodeTime + 's'"
            />
          </div>
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input v-model="loginParams.code" placeholder="请输入验证码" />
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input
            v-model="loginParams.password"
            type="password"
            :eye="{ open: false, reverse: false }"
            placeholder="设置新密码(6-30位且不包含空格)"
          />
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input
            v-model="loginParams.repassword"
            type="password"
            :eye="{ open: false, reverse: false }"
            placeholder="确认密码"
          />
        </div>
        <div class="btn-wrap login">
          <cube-button @click="resetPwdFun">重置密码</cube-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { mixins } from "@/assets/mixin/login";
import { getCodeMsg, resetPassword } from "@/api/user";
export default {
  props: {
    name: {
      type: String,
      default: "忘记密码"
    }
  },
  mixins: [mixins],
  components: {
    h
  },
  data() {
    return {
      showFlag: false,
      pwdCodeTime: 0
    };
  },
  created() {
    this.getCountryList();
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    getPwdCode() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入手机号码");
        return;
      }
      if (this.pwdCodeTime > 0) {
        return;
      }
      getCodeMsg(
        3,
        this.loginParams.phone,
        this.defaultCountry.countryCode
      ).then(res => {
        if (!res) {
          return;
        }
        this.pwdCodeTime = 60;
        const timer = setInterval(() => {
          this.pwdCodeTime--;
          if (this.pwdCodeTime <= 0) {
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    resetPwdFun() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入手机号码");
        return;
      }
      if (!this.loginParams.code) {
        this.showToast("warn", "请输入验证码");
        return;
      }
      if (!this.loginParams.password) {
        this.showToast("warn", "请输入新密码");
        return;
      }
      if (this.loginParams.password !== this.loginParams.repassword) {
        this.showToast("warn", "两次输入的密码不一致，请重新输入");
        return;
      }
      const params = {
        phone: this.loginParams.phone,
        code: this.loginParams.code,
        password: this.loginParams.password,
        password2: this.loginParams.repassword,
        countryCode: this.defaultCountry.countryCode
      };
      resetPassword(params).then(res => {
        if (!res) {
          return;
        }
        this.showToast("correct", "重置密码成功，请重新登录");
        this.$emit("hide");
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .form
    padding 30px 0 0
    .login
      margin-top 25px
</style>
