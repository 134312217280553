import { mapState, mapMutations, mapActions } from "vuex";
import cookie from "js-cookie";
export const mixins = {
  data() {
    return {
      defaultCountry: {
        countryCode: "",
        chineseShortName: ""
      },
      loginParams: {},
      loginBtn: false
    };
  },
  computed: {
    ...mapState({
      countryList: state => state.user.countryList
    })
  },
  watch: {
    countryList(list) {
      this.defaultCountry = list[0];
    }
  },
  methods: {
    ...mapMutations({
      setCookie: "user/SET_TOKEN"
    }),
    ...mapActions({
      getCountryList: "user/_getCountList",
      getUserInfo: "user/_getUserInfo"
    }),
    showAliasPicker() {
      if (!this.aliasPicker) {
        console.log(this.countryList);
        this.aliasPicker = this.$createPicker({
          title: "国家与地区",
          data: [this.countryList],
          alias: {
            value: "countryCode",
            text: "chineseShortName"
          },
          onSelect: this.selectHandle
        });
      }
      this.aliasPicker.show();
    },
    selectHandle(selectedVal, selectedIndex) {
      this.defaultCountry = this.countryList[selectedIndex];
    },
    showToast(type, txt) {
      const toast = this.$createToast({
        type,
        time: 1500,
        txt
      });
      toast.show();
    },
    loginAfter(obj) {
      const options = { expires: 365 };
      cookie.set("token", obj.authentication, options);
      this.setCookie(obj.authentication);
      this.getUserInfo();
      this.showToast("correct", "登录成功");
      this.$router.back();
    }
  }
};
